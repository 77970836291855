<template>
    <div class="row">
        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />
         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->

                    <!--information_general start-->
                    <InformationGeneral v-if="key == 'detail_information_general' && item.show" />
                    <!--information_general end-->  

                    <!--indication start-->
                    <Indication v-if="key == 'detail_indication' && item.show"/> 
                    <!--indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show" />
                    <!-- treatment_line end -->

                    <!-- detail_ItalyMea start -->
                    <div class="content-onglets table" v-else-if="key == 'detail_ItalyMea' && item.show">
                    <table class="comparateur2">
                        <thead>
                            <tr>
                                <th width="33%" >Granted</th>
                                <th width="33%" v-if="data.italy['start_date_mea_648']">Start date</th>         
                                <th width="33%" v-if="data.italy['end_date_mea_648']">End date</th>  
                            </tr>
                        </thead>
                        <tbody>                    
                            <tr>
                                <td style="vertical-align: middle"> {{$t('Oui')}} </td>
                                <td style="vertical-align: middle" v-if="data.italy['start_date_mea_648']">
                                     {{DDMMMYY(data.italy['start_date_mea_648'])}}
                                </td>
                                <td style="vertical-align: middle" v-if="data.italy['end_date_mea_648']">
                                    {{DDMMMYY(data.italy['end_date_mea_648'])}}
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- detail_ItalyMea end -->

                <!-- detail_reimbursement start -->
                <div class="content-onglets table" v-else-if="key == 'detail_reimbursement' && item.show">
                    <table class="comparateur2 tb-class-head">
                        <thead>
                            <tr>
                                <th width="25%" >Reimbursement</th>
                                <th width="25%" >Class</th>
                                <th width="25%" >Reimbursement application date</th>
                                <th width="25%" >PDF</th>                  
                            </tr>
                        </thead>
                        <tbody>                    
                            <tr>
                                <td style="vertical-align: middle">
                                    {{data.italy['rembursement_process'] ? data.italy['rembursement_process'] : '-'}}
                                </td>
                                <td style="vertical-align: middle">
                                    {{data.italy['drug_class'] && data['italy']['drug_class'].split(':')[0] ? data['italy']['drug_class'].split(':')[0]  : '-'}}
                                </td>
                                <td style="vertical-align: middle">
                                    {{data.italy['rembursement_process_date'] ? DDMMMYY(data.italy['rembursement_process_date']) : '-'}} 
                                </td>
                                <td style="vertical-align: middle">
                                    <span v-if="data.italy['gu_document']">
                                        <LinkUpload :href="'/uploads/Italy/' + $route.params.id + '/gu_document.pdf'" target="_blank" rel="noopener"><em class="icon-fiche"></em></LinkUpload>
                                    </span>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- detail_reimbursement end -->

                <!-- detail_ItalyMea2 start -->
                <div class="content-onglets table" v-else-if="key == 'detail_ItalyMea2' && item.show">
                    <table class="comparateur2">
                        <thead>
                            <tr>
                                <th width="30%" >Manage entry agreement</th>
                                <th width="30%" >Type of MEA procedure</th>              
                                <th width="20%" >Start date</th>                 
                                <th width="20%" >End date</th>                     
                            </tr>
                        </thead>
                        <tbody>                    
                            <tr v-for="(value, key) in data.italy['italy_mea']" :key="'ItalyMea_'+key">
                                <td style="vertical-align: middle"> {{value['mea'] ? value['mea'] : '-'}} </td>
                                <td style="vertical-align: middle"> {{value['mea_procedure'] ? value['mea_procedure'] : '-'}} </td>
                                <td style="vertical-align: middle"> {{value['start_date_mea'] ? DDMMMYY(value['start_date_mea']) : '-'}} </td>
                                <td style="vertical-align: middle"> {{value['end_date_mea'] ? DDMMMYY(value['end_date_mea']) : '-'}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- detail_ItalyMea2 end -->

                <!--Comments start-->
                <div class="content-onglets" v-else-if="key == 'detail_comments_original' && item.show">
                    <p v-html="data.italy['comments']"></p>
                </div>
                <!--Comments start-->

                <!-- detail_Conclusion start-->
                <Conclusion v-else-if="key == 'detail_Conclusion' && item.show" />
                <!-- detail_Conclusion end-->

                <!--Evidence in brief start-->
                <div class="content-onglets" v-else-if="key == 'detail_evidence_in_brief_original' && item.show">
                    <p v-html="data.italy['evidence_in_brief']"></p>
                </div>
                <!--Evidence in brief end-->

                <!-- detail_guidance start -->
                <div class="content-onglets" v-else-if="key == 'detail_guidance' && item.show">
                    <template v-if="data.italy['title']">
                        <strong> Document title :</strong>
                        <p> {{data.italy['title']}} </p>
                    </template>
                    <template v-if="data.italy['project_number']">
                        <strong> Project number : </strong>
                        <p> {{data.italy['project_number']}} </p> 
                    </template>
                    <template v-if="data.italy['agency']">
                        <strong> Agency : </strong>
                        <p> {{data.italy['agency']}} </p> 
                    </template>
                </div>
                <!-- detail_guidance end -->

                <!-- detail_aifa_registration start -->
                <div class="content-onglets" v-else-if="key == 'detail_aifa_registration' && item.show"> 
                    <p v-if="data.italy['aifa_registration_yes_no']"> {{$t(data.italy['aifa_registration_yes_no'])}}  </p>
                    <p v-html="data.italy['aifa_registration']"></p>
                </div>
                <!-- detail_aifa_registration end -->

                <!--Classification specification start-->
                <div class="content-onglets" v-else-if="key == 'detail_classification_specification' && item.show">
                    <template v-if="data.italy['classification_specification'] || data.italy['drug_class']">
                        <strong> Drug Classification </strong>
                        <p v-if="data.italy['drug_class']">  {{data.italy['drug_class']}}  </p> 
                        <p v-html="data.italy['classification_specification'] ? data.italy['classification_specification'] : '-'"> </p>
                    </template>
                    <template v-if="data.italy['therapeutic_innovation']">
                        <strong> Therapeutic innovation </strong>
                        <p v-html="data.italy['therapeutic_innovation']"></p>
                    </template>
                </div>
                <!--Classification specification end-->

                <!--Background start-->
                <div class="content-onglets" v-else-if="key == 'detail_background_original' && item.show">
                    <p v-html="data.italy['background']"></p>
                </div>
                <!--Background end-->

                <!-- detail_ItalyConclusion  start -->
                <div class="content-onglets table" v-else-if="key == 'detail_ItalyConclusion' && item.show">    
                    <div class="accordion accordion-table">
                        <template v-for="(value, key) in data.italy['italy_conclusion']" :key="'conclusion_recommendation2_'+key">
                            <span v-html="value['conclusion_recommendation'] ? value['conclusion_recommendation'] : '-'" ></span>

                            <div class="table-responsive" >   
                                <div class="accordion-group" style="margin: 0 0 10px 0;">
                                    <div class="accordion-heading" >
                                        <table class="comparateur2 table-heading border-top-gray" style="margin-bottom:0px;" >
                                            <tbody>
                                                <tr>
                                                    <td width="25%" class="gray"><strong> Recommendation </strong></td>
                                                    <td width="75%" class="bg-white">  {{value['recommendation']}} </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                        <a class="accordion-toggle text-blank" @click="ItalyConclusion_value_toggle(key)">
                                            <em :class="ItalyConclusion_value_show[key] === 1 ? 'icons-2014 icon-minus-s' : 'icons-2014 icon-plus-s'"></em>
                                        </a>
                                    </div>    
                                    <div class="accordion-body" v-if="ItalyConclusion_value_show[key] === 1">
                                        <div style="position: static;"  >
                                            <table class="comparateur2 tb-border text-center" style="margin: 0;" >
                                                <tbody>
                                                    <tr v-if="value['risk_ratio'] || value['risk_ratio_text']">
                                                        <td width="25%" class="gray" style="border-bottom:1px solid #fff;">
                                                            Benefit/Risk ratio
                                                        </td>
                                                        <td width="30%" class="bg-white" v-if="value['risk_ratio']">
                                                            {{value['risk_ratio'] ? value['risk_ratio'] : '-'}}
                                                        </td>
                                                        <td width="45%" class="bg-white" v-if="check_text_conclusion(value)">
                                                            {{value['risk_ratio_text'] ? value['risk_ratio_text'] : '-'}}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="value['quality_evidence'] || value['quality_evidence_text']">
                                                        <td width="25%" class="gray"  style="border-bottom:1px solid #fff;">
                                                            Quality of the evidence
                                                        </td>
                                                        <td width="30%" class="bg-white" v-if="value['quality_evidence']">
                                                            {{value['quality_evidence'] ?  value['quality_evidence'] : '-'}}
                                                        </td>
                                                        <td width="45%" class="bg-white" v-if="check_text_conclusion(value)">
                                                            {{value['quality_evidence_text'] ? value['quality_evidence_text'] : '-'}}
                                                        </td>
                                                    </tr>
                                                    <tr v-if="value['therapeutic_alternative'] || value['therapeutic_alternative_text']">
                                                        <td width="25%" class="gray" style="border-bottom:1px solid #fff;">
                                                            Therapeutic alternative
                                                        </td>
                                                        <td width="30%" class="bg-white" v-if="value['therapeutic_alternative']">
                                                            {{value['therapeutic_alternative'] ? value['therapeutic_alternative'] : '-'}}
                                                        </td>
                                                        <td width="45%" class="bg-white" v-if="check_text_conclusion(value)">
                                                            {{value['therapeutic_alternative_text'] ? value['therapeutic_alternative_text'] : '-'}}
                                                        </td>
                                                    </tr>

                                                    <tr v-if="value['cost'] || value['cost_text']">
                                                        <td width="25%" class="gray"   style="border-bottom:1px solid #fff;">
                                                            Cost
                                                        </td>
                                                        <td width="30%" class="bg-white" v-if="value['cost']">
                                                            {{value['cost'] ? value['cost'] : '-'}}
                                                        </td>
                                                        <td width="45%" class="bg-white" v-if="check_text_conclusion(value)">
                                                            {{value['cost_text'] ? value['cost_text'] : '-'}} 
                                                        </td>
                                                    </tr>

                                                    <tr v-if="value['comment']">
                                                        <td width="25%" class="gray vertical-md" style="border-bottom:1px solid #fff;">
                                                            Comment
                                                        </td>
                                                        <td class="bg-white" width="30%" :colspan="check_text_conclusion(value) ? 2 : 1"  style="text-align:justify;"> 
                                                            <p v-html="value['comment'] ? value['comment'] : '-'"></p>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>    
                            </div> 
                        </template>
                    </div>
                </div> 
                <!-- detail_ItalyConclusion  end -->

                <!-- detail_target_population_original start -->
                <div class="content-onglets table" v-else-if="key == 'detail_target_population_original' && item.show">
                    <p v-if="data.italy['target_population']" v-html="data.italy['target_population']"></p>
                    <table class="comparateur2 border-top-gray" v-if="data['target_population_number'] || data['time_period']">
                        <tbody>
                            <tr v-if="data.italy['target_population_number']">
                                <td width="25%" style="background: #e0dcd9;color:#000000;">
                                    Number of patients
                                </td>
                                <td width="75%"> {{data.italy['target_population_number']}} </td>

                            </tr>
                            <tr v-if="data.italy['time_period']">
                                <td width="25%" style="background: #e0dcd9;color:#000000;">
                                    Time period
                                </td>
                                <td width="75%"> {{data.italy['time_period']}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- detail_target_population_original end -->

                <!-- detail_comparator_original start -->
                <div class="content-onglets table" v-else-if="key == 'detail_comparator_original' && item.show">
                    <table class="comparateur2">
                        <thead>
                            <tr>
                                <th width="33%"> Comparator name </th>
                                <th width="33%"> Acceptance </th>
                                <th width="33%"> Relevance </th>                       
                            </tr>
                        </thead>

                        <tbody>
                            <tr v-for="(value_comname, key_comname) in data.italy['italy_comparator_name']" :key="'ItalyComparatorName_'+key_comname">
                                <td> {{value_comname['comparator_name'] ? value_comname['comparator_name'] : '-'}} </td>
                                <td> {{value_comname['accepted'] ? value_comname['accepted'] : '-'}} </td>
                                <td v-if="key_comname === 0" :rowspan="data.italy['italy_comparator_name'].length" class="text-left"> 
                                    <span v-html="data['italy']['comparator_original'] ? data['italy']['comparator_original'] : '-'"></span>
                                </td>
                            </tr> 
                        </tbody>
                    </table>
                </div>
                <!-- detail_comparator_original end -->

                <!-- detail_ItalyClinicalRationale start -->
                <div class="content-onglets table" v-else-if="key == 'detail_ItalyClinicalRationale' && item.show"> 
                    <template v-for="(value, key) in data.italy['italy_clinical_rationale']" >
                        <div class="textJustify" v-if="value['italy_primary_endpoint'].length > 0" :key="'ItalyClinicalRationale_primary_endpoint_'+key">                        
                            <strong  @click="primary_endpoint_toggle(key)" style="cursor: pointer;">
                                Clinical studies and outcomes
                                <em class="fa fa-angle-down icon-arrow-updown"></em>                           
                            </strong>
                            <div v-if="primary_endpoint_show[key]">
                                <template v-for="(item_primary, key_primary) in value['italy_primary_endpoint']" :key="'italy_primary_endpoint_'+key_primary">
                                    <p v-html="item_primary['primary_endpoint']" ></p>
                                </template>               
                            </div>                        
                        </div>

                        <template v-if="value['italy_comparator_name'].length > 0">
                            <strong :key="'ItalyClinicalRationale_ItalyComparatorName_title'+key">Comparator</strong>
                            <div class="table-responsive" :key="'ItalyClinicalRationale_ItalyComparatorName_'+key">   
                                <table class="comparateur2 text-center" >
                                    <thead>
                                        <tr>
                                            <th width="75%"> Name </th>
                                            <th width="25%"> Acceptance </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="(item_comparator_name, key_comparator_name) in value['italy_comparator_name']" :key="'key_comparator_name_'+key_comparator_name">
                                            <td>
                                                {{item_comparator_name['comparator_name'] ? item_comparator_name['comparator_name'] : '-'}}
                                            </td>
                                            <td>
                                                {{item_comparator_name['accepted'] ? item_comparator_name['accepted'] : '-'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="value['clinical_efficacy_list'] || value['clinical_efficacy']">
                            <strong :key="'clinical_key_title_'+key">Clinical Efficacy</strong>
                            <div class="table-responsive" :key="'clinical_key_'+key">   
                                <table class="comparateur2 text-center border-top-gray" >
                                    <tbody>
                                        <tr>
                                            <td width="25%" >
                                                {{value['clinical_efficacy_list'] ? value['clinical_efficacy_list'] : '-'}}
                                            </td>
                                            <td width="75%" >
                                                {{value['clinical_efficacy'] ? value['clinical_efficacy'] : '-'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="value['safety_profile_list1'] || value['safety_profile1'] || value['safety_profile_list2'] || value['safety_profile2']">
                            <strong :key="'safety_title_'+key">Safety profile</strong>
                            <div class="table-responsive" :key="'safety_'+key">   
                                <table class="comparateur2 text-center border-top-gray" >
                                    <tbody>
                                        <tr v-if="value['safety_profile_list1'] || value['safety_profile1']">
                                            <td width="25%">
                                                {{value['safety_profile_list1'] ? value['safety_profile_list1'] : '-'}}
                                            </td>
                                            <td width="75%">
                                                {{value['safety_profile1'] ? value['safety_profile1'] : '-'}}
                                            </td>
                                        </tr>
                                        <tr v-if="value['safety_profile_list2'] || value['safety_profile2']">
                                            <td width="25%" >
                                                {{value['safety_profile_list2'] ? value['safety_profile_list2'] : '-'}}
                                            </td>
                                            <td width="75%" >
                                                {{value['safety_profile2'] ? value['safety_profile2'] : '-'}}
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </template>

                        <template v-if="value['comments']">
                            <strong :key="'comments_'+key"> Comments </strong>
                            <span v-html="value['comments']" :key="'comment_'+key"></span>
                        </template>

                    </template>
                </div>
                <!-- detail_ItalyClinicalRationale end -->

                <!-- detail_ItalyClinicalTrial start -->
                <div class="content-onglets table" v-else-if="key == 'detail_ItalyClinicalTrial' && item.show">
                    <table class="comparateur2 text-center">
                        <thead>
                            <tr>
                                <th width="100%" > {{$t('Clinical Trials')}} </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(value, key) in data.italy['italy_clinical_trial']" :key="'ItalyClinicalTrial_'+key">
                                <td> {{value['clinical_trial'] ? value['clinical_trial'] : '-'}} </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <!-- detail_ItalyClinicalTrial end -->

                <!-- detail_ItalyPrice start -->
                <div class="content-onglets table" v-else-if="key == 'detail_ItalyPrice' && item.show">

                    <template v-if="data.italy['italy_price'].length > 0">
                        <strong> Price </strong>
                        <table class="comparateur2 text-center" >
                            <thead>
                                <tr> 
                                    <th width="33%" v-if="chk_ItalyPrice('price')"> Price </th>  
                                    <th width="33%" v-if="chk_ItalyPrice('dosage')"> Dosage </th>    
                                    <th width="33%" v-if="chk_ItalyPrice('comparator')"> Vs comparator </th>    
                                    <th width="33%" v-if="chk_ItalyPrice('text_comparator')"> {{$t('comparator')}} </th>    
                                </tr>
                            </thead>
                            <tbody >
                                <tr v-for="(value, key) in data.italy['italy_price']" :key="'ItalyPrice_'+key">      
                                    <td v-if="chk_ItalyPrice('price')"> {{value['price'] ? value['price'] : '-'}} </td>
                                    <td v-if="chk_ItalyPrice('dosage')"> {{value['dosage'] ? value['dosage'] : '-'}} </td>
                                    <td v-if="chk_ItalyPrice('comparator')"> {{value['comparator'] ? value['comparator'] : '-'}} </td>
                                    <td v-if="chk_ItalyPrice('text_comparator')"> {{value['text_comparator'] ? value['text_comparator'] : '-'}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>

                    <template v-if="data.italy['italy_cost_treatment'].length > 0">
                        <strong> Cost of treatment </strong>    
                        <table class="comparateur2 text-center">
                            <thead>
                                <tr>   
                                    <th width="60%" v-if="chk_ItalyCostTreatment('cost_of_treatment')"> Cost of treatment </th>  
                                    <th width="40%" v-if="chk_ItalyCostTreatment('comparator')"> Vs comparator_name </th>     
                                    <th width="40%" v-if="chk_ItalyCostTreatment('text_comparator')"> {{$t('comparator')}} </th>     
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(value, key) in data.italy['italy_cost_treatment']" :key="'ItalyCostTreatment_'+key">
                                    <td v-if="chk_ItalyCostTreatment('cost_of_treatment')"> {{value['cost_of_treatment']}} {{value['gross'] ? " - Gross" : ""}} {{value['net'] ? " - Net" : ""}} </td>
                                    <td v-if="chk_ItalyCostTreatment('comparator')"> {{value['comparator']}} </td>
                                    <td v-if="chk_ItalyCostTreatment('text_comparator')"> {{value['text_comparator']}} </td>
                                </tr>
                            </tbody>
                        </table>
                    </template>
                </div>
                <!-- detail_ItalyPrice end -->

                
                <!--BudgetImpact start-->
                <BudgetImpact :data="data.italy['budget_impacts']" v-else-if="key == 'detail_BudgetImpact' && item.show" /> 
                <!--BudgetImpact end-->

                <!--EssaisClinique start-->
                <EssaisClinique v-else-if="key == 'detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                <!--EssaisClinique end-->   

                <!-- detail_EvaluationEconomic  -->
                <EvaluationEconomic v-else-if="key == 'detail_EvaluationEconomic' && item.show" :data="data.italy.evaluation_economic" />

                <!-- detail_EconomicEvaluation -->
                <EconomicEvaluation v-else-if="key == 'detail_EconomicEvaluation' && item.show" :data="data.data_links['EconomicEvaluation']" />

                <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                <!-- Section Detail End -->
                </div>
            </template>
            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LinkUpload from '../LinkUpload.vue'
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import Conclusion from '../conclusion.vue'
import BudgetImpact from '../budget_impact.vue'
import EvaluationEconomic from '../evaluation_economic.vue'
import EconomicEvaluation from '../economic_evaluation.vue'
import LinkAgency from '../LinkAgency.vue'
import EssaisClinique from '../../elements/essais_clinique_detail.vue'
import KeyDocument from '../key_document.vue'
import InformationGeneral from '../InformationGeneral.vue'
import Indication from '../Indication.vue'
import { DDMMMYY, check_detail_conclusion, key_documents } from '../../../utils'

export default{
    name: 'italy',
    components : {
        LinkUpload,
        LeftSection,
        TreatmentLine,
        Conclusion,
        BudgetImpact,
        EvaluationEconomic,
        EconomicEvaluation,
        LinkAgency,
        EssaisClinique,
        KeyDocument,
        InformationGeneral,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                
                detail_information_general : { 'title' : 'information generale', 'show' : true, 'enable' : false, 'orange_text':false},               
                detail_indication : {'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false },
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_ItalyMea : {'title' : 'Early access (Law 648/96)', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_reimbursement : {'title' : 'Reimbursement', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_ItalyMea2 : {'title' : 'Managed entry agreement', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_comments_original : {'title' : 'Comments', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_Conclusion : {'title' : 'Rationale and Commentary', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_evidence_in_brief_original : {'title' : 'Evidence in brief', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_guidance : {'title' : 'Guidance', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_aifa_registration : {'title' : 'AIFA registration', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_classification_specification : {'title' : 'Drug Classification', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_background_original : {'title' : 'Background', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_ItalyConclusion : {'title' : 'Conclusion', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_target_population_original : {'title' : 'Target population', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_comparator_original : {'title' : 'Comparator', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_ItalyClinicalRationale : {'title' : 'Clinical rationale', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_ItalyClinicalTrial : {'title' : 'Clinical Trials', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_ItalyPrice : {'title' : 'Price and cost', 'show' : true, 'enable' : false, 'orange_text':false },             
                detail_BudgetImpact : {'title' : 'Budget impact', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },  
                detail_EvaluationEconomic : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_EconomicEvaluation : {'title' : 'Economic evaluation', 'show' : true, 'enable' : false, 'orange_text':true },  
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },            

            },
            primary_endpoint_show : {},
            ItalyConclusion_value_show : {},              
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.italy){
            
            if(this.data['ma_date'])
            {
                this.list_check_box['detail_information_general'].enable = true
            }

            if(this.data['indication_original'] || this.data['indication_en'])
            { 
                this.list_check_box['detail_indication'].enable = true
            }

            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }

            if(this.data.italy['region'] === "AIFA")
            {
                if(this.data.italy['law_648'])
                {
                    this.list_check_box['detail_ItalyMea'].enable = true
                }
                if(this.data.italy['rembursement_process'] || this.data.italy['rembursement_process_date'] ||
                    this.data.italy['drug_class'] || this.data.italy['gu_document'])
                {
                    this.list_check_box['detail_reimbursement'].enable = true
                }
                if(this.data.italy['italy_mea'].length > 0)
                {
                    this.list_check_box['detail_ItalyMea2'].enable = true
                }
            }  
            
            if(this.data.italy['comments'])
            {
                this.list_check_box['detail_comments_original'].enable = true
            }
            if(this.check_detail_conclusion(this.data, this.$i18n.locale) || this.data.italy['limitation'] || this.data.italy['italy_conclusion'].length > 0 )
            {
                this.list_check_box['detail_Conclusion'].enable = true
            }
            if(this.data.italy['evidence_in_brief'])
            {
                this.list_check_box['detail_evidence_in_brief_original'].enable = true
            }
            if(this.data.italy['title'] || this.data.italy['project_number'])
            {
                this.list_check_box['detail_guidance'].enable = true
            }
            if(this.data.italy['aifa_registration'])
            {
                this.list_check_box['detail_aifa_registration'].enable = true
            }
            if(this.data.italy['classification_specification'])
            {
                this.list_check_box['detail_classification_specification'].enable = true
            }
            if(this.data.italy['background'])
            {
                this.list_check_box['detail_background_original'].enable = true
            }
            if(this.data.italy['italy_conclusion'].length > 0)
            {
                for(let key in this.data.italy['italy_conclusion']){
                    this.ItalyConclusion_value_show[key] = 0
                }
                
                this.list_check_box['detail_ItalyConclusion'].enable = true
            }
            if(this.data.italy['target_population_original'] || this.data.italy['target_population_number'] || this.data.italy['time_period'])
            {
                this.list_check_box['detail_target_population_original'].enable = true
            }
            if(this.data.italy['italy_comparator_name'].length > 0)
            {
                this.list_check_box['detail_comparator_original'].enable = true
            }
            if(this.data.italy['italy_clinical_rationale'].length > 0)
            {
                for(let key in this.data.italy['italy_clinical_rationale']){
                    this.primary_endpoint_show[key] = 0
                }
                this.list_check_box['detail_ItalyClinicalRationale'].enable = true
            }
            if(this.data.italy['italy_clinical_trial'].length > 0)
            {
                this.list_check_box['detail_ItalyClinicalTrial'].enable = true
            }
            if(this.data.italy['italy_price'].length > 0 || this.data.italy['italy_cost_treatment'].length > 0)
            {
                this.list_check_box['detail_ItalyPrice'].enable = true
            }
            if(this.data.italy['budget_impacts'].length > 0)
            {
                this.list_check_box['detail_BudgetImpact'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }
            if(this.data.italy.evaluation_economic.length > 0) { 
                this.list_check_box['detail_EvaluationEconomic'].enable = true
            }

            if(this.data.data_links && this.data.data_links['EconomicEvaluation'] && this.data.data_links['EconomicEvaluation'].length > 0) { 
            
                this.list_check_box['detail_EconomicEvaluation'].enable = true
                this.list_check_box['detail_EvaluationEconomic'].enable = false
            }	 
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }

        }
    },
    methods : {
        DDMMMYY,
        check_detail_conclusion,
        key_documents,
        chk_ItalyPrice(value){
            let chk = {}
            chk['price'] = false;
            chk['dosage'] = false;
            chk['comparator'] = false;
            chk['text_comparator'] = false;
            
            for(let key in this.data.italy['italy_price']) {
                let value_2 = this.data.italy['italy_price'][key]
                chk['price'] = value_2['price'] ? true : chk['price']
                chk['dosage'] = value_2['dosage'] ? true : chk['price']
                chk['comparator'] = value_2['comparator'] ? true : chk['comparator']
                chk['text_comparator'] = value_2['text_comparator'] ? true : chk['text_comparator']
            }

            return chk[value]
        },
        chk_ItalyCostTreatment(value){
            let chk = {}
            chk['cost_of_treatment'] = false;
            chk['comparator'] = false;
            chk['text_comparator'] = false;
            for (let key in this.data.italy['italy_cost_treatment']) {
                let value2 = this.data.italy['italy_cost_treatment'][key]
                chk['cost_of_treatment'] = value2['cost_of_treatment'] ? true : chk['cost_of_treatment']
                chk['comparator'] = value2['comparator'] ? true : chk['comparator']
                chk['text_comparator'] = value2['text_comparator'] ? true : chk['text_comparator']
            }
            return chk[value]
        },
        ItalyConclusion_value_toggle(key){
            if(this.ItalyConclusion_value_show[key] === 1){
                this.ItalyConclusion_value_show[key] = 0
            }else{
                this.ItalyConclusion_value_show[key] = 1
            } 
            this.$forceUpdate();
        },
        primary_endpoint_toggle(key){
            if(this.primary_endpoint_show[key] === 1){
                this.primary_endpoint_show[key] = 0
            }else{
                this.primary_endpoint_show[key] = 1
            } 
            this.$forceUpdate();
        },
        check_text_conclusion(value){
            return value['therapeutic_alternative_text'] || value['quality_evidence_text'] || value['risk_ratio_text'] || value['cost_text']
        }
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }     
    },
}

</script>

<style scoped>
.accordion-table .accordion-body p:first-child {
        margin-top: 0px !important;
}

</style>